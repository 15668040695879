import { Component, OnInit } from '@angular/core';
import { AuthService, User } from '@auth0/auth0-angular';
import { NgIf, AsyncPipe } from '@angular/common';
import { HighlightModule } from 'ngx-highlightjs';
import { Stripe } from 'stripe'
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

const stripe = new Stripe('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')
//new Stripe('pk_live_51PlcT801QwAblKVIPOdqphbMaYbACwlu2NgY5gPjoDBjAjtZt1M4WHA854doiSMAAITVgrw9uSO6bEyg4XlaoLPr009j0ElCMw');

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        HighlightModule,
        AsyncPipe,
    ],
})
export class ProfileComponent implements OnInit {
  profileJson: string = null;
  stripePortalUrl: string = null;
  user: User;
  singleUser$: Observable<User>;
  unsubscribe$: Subject<any> = new Subject();

  constructor(public authService: AuthService) {}

  ngOnInit() {
    var self = this;
    this.authService.user$.subscribe(user => this.user = user);

    this.authService.user$.subscribe(
      (profileJson) => (this.profileJson = JSON.stringify(profileJson, null, 2))
    );

    this.authService.user$.pipe(takeUntil(this.unsubscribe$)).subscribe((users) => {
      const sci = this.user['stripe_customer_id'];

      console.log(this.user)
      const customer = stripe.customers.retrieve(sci)
      console.log(customer);
      this.stripeCreate();
      this.stripeSession(sci).then(function(result) 
      { 
        self.stripePortalUrl = result;
        console.log(result); 
      });
   });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
   }
  
  
  logout() {
    this.authService.logout();
  }

  stripeSession = async function (sci: string): Promise<string> {
    const session = await stripe.billingPortal.sessions.create({
      customer: sci,
      return_url: `${document.location.origin}/profile`,
    });
    console.log(session);
    console.log(session.url);
    return session?.url;
  }
  
  stripeCreate = async function() {
    var configuration = await stripe.billingPortal.configurations.create({
      business_profile: {
        headline: 'Cactus Practice partners with Stripe for simplified billing.',
      },
      features: {
        invoice_history: {
          enabled: true
        }
      },
    });
    console.log(configuration);
  }

}
