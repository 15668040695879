<div class="page-banner" *ngIf=" this.user">

  <div class="banner-container">
      <h1>Dashboard</h1>
      <p>Welcome back, {{ user.name }}</p>
      <a href="{{ stripePortalUrl }}" class="button">Billing</a>
      <a (click)="logout()" class="button">Logout</a>
  </div>
</div>

<div class="page-content">

  <div class="page-wrapper">

    <div class="container mt-5">
      <div
        class="row align-items-center profile-header mb-5 text-center text-md-left"
      >
        <div class="col-md-2">
          <img [src]="user.picture" />
        </div>
        <div class="col-md">
          <h2>{{ user.full_name }}</h2>
          <p>{{ user.email }}</p>
          <p>{{ user['stripe_customer_id'] }}</p>
          {{ user['full_name'] }}
        </div>
      </div>
    
      <div class="row" *ngIf="profileJson">
        <pre class="rounded"><code class="json" [highlight]="profileJson"></code></pre>
      </div>
    </div>

    </div>
</div>

